const formatDistanceLocale = {
	lessThanXSeconds: {
		one: 'menos de 1 minuto',
		other: 'menos de {{count}} minutos',
	},

	xSeconds: {
		one: 'menos de 1 minuto',
		other: 'menos de {{count}} minutos',
	},

	halfAMinute: 'menos de 1 minuto',

	lessThanXMinutes: {
		one: 'menos de 1 minuto',
		other: 'menos de {{count}} minutos',
	},

	xMinutes: {
		one: '1 minuto',
		other: '{{count}} minutos',
	},

	aboutXHours: {
		one: '1 hora',
		other: '{{count}} horas',
	},

	xHours: {
		one: '1 hora',
		other: '{{count}} horas',
	},

	xDays: {
		one: '1 dia',
		other: '{{count}} dias',
	},

	aboutXMonths: {
		one: '1 mês',
		other: '{{count}} meses',
	},

	xMonths: {
		one: '1 mês',
		other: '{{count}} meses',
	},

	aboutXYears: {
		one: '1 ano',
		other: '{{count}} anos',
	},

	xYears: {
		one: '1 ano',
		other: '{{count}} anos',
	},

	overXYears: {
		one: 'mais de 1 ano',
		other: 'mais de {{count}} anos',
	},

	almostXYears: {
		one: 'quase 1 ano',
		other: 'quase {{count}} anos',
	},
}

export const formatDistance = (token: string, count: number, options: any) => {
	options = options || {}

	let result: string
	// @ts-ignore
	if (typeof formatDistanceLocale[token] === 'string') {
		// @ts-ignore
		result = formatDistanceLocale[token]
	} else if (count === 1) {
		// @ts-ignore
		result = formatDistanceLocale[token].one
	} else {
		// @ts-ignore
		result = formatDistanceLocale[token].other.replace('{{count}}', count)
	}

	if (options.addSuffix) {
		return 'Há ' + result
	}

	return result
}
