import { useState, useEffect } from 'react'
import { formatDateRelative, formatDateDay, DateFormats, formatDateLocale } from 'utils/dates'
import { useInterval } from 'react-use'
import { Language } from 'generated/sdk'

const formatDate = (timestamp: string, language: Language, format: DateFormats) => {
	if (!timestamp) {
		return ''
	}
	switch (format) {
		case DateFormats.relative:
			return formatDateRelative(timestamp, { addSuffix: true }, language)
		case DateFormats.day:
			return formatDateDay(timestamp, language)
		default:
			return formatDateLocale(timestamp, language)
	}
}

export const useFormattedDate = (timestamp: string, language: Language, format = DateFormats.relative) => {
	const [counter, setCounter] = useState(0)
	const [formattedDate, setFormattedDate] = useState(() => formatDate(timestamp, language, format))
	const isRelative = format === DateFormats.relative
	const intervalDelay = isRelative ? 60 * 1000 : null

	useEffect(() => {
		setFormattedDate(formatDate(timestamp, language, format))
	}, [timestamp, counter, language, format])

	useInterval(() => {
		if (intervalDelay) {
			setCounter(counter + 1)
		}
	}, intervalDelay)

	return formattedDate
}
