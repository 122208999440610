/* eslint-disable jsx-a11y/no-access-key */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Language } from 'generated/sdk'
import { useLocationContext } from 'components/location-provider/location-provider'
import { useReadSpeakerContext } from 'components/read-speaker-provider/read-speaker-provider'

type ReadSpeakerPluginProps = {
	readId?: string
	url: string
}

export const READ_SPEAKER_TEXT = {
	title: {
		[Language.Pt]: 'Ouvir com ReadSpeaker webReader',
		[Language.En]: 'Listen to this page using ReadSpeaker webReader',
	},
	label: {
		[Language.Pt]: 'Ouvir',
		[Language.En]: 'Listen',
	},
}

const ReadSpeakerPluginButton = styled.div`
	flex: 1 1 auto;
	padding-bottom: 0; /* overwrite rs specificity */
`

const ReadSpeakerPluginPlay = styled.a`
	margin-bottom: 0 !important; /* overwrite rs specificity */
`

export const ReadSpeakerPlugin = ({ readId, url }: ReadSpeakerPluginProps) => {
	const { language, origin } = useLocationContext()
	const { isReadSpeakerLoaded } = useReadSpeakerContext()

	const absoluteUrl = url.startsWith('/') ? `${origin}${url}` : url
	const langId = language === 'pt' ? 'pt_pt' : 'en_uk'
	const id = readId ? readId : 'js-read-speaker'

	useEffect(() => {
		if (isReadSpeakerLoaded || typeof ReadSpeaker !== 'undefined') {
			ReadSpeaker.init()
			ReadSpeaker.q(() => {
				rspkr.ui.addClickEvents()
			})
		}

		return () => {
			if (isReadSpeakerLoaded || typeof ReadSpeaker !== 'undefined') {
				ReadSpeaker.q(() => {
					if (rspkr.ui.getActivePlayer()) {
						rspkr.ui.getActivePlayer().close()
					}
				})
			}
		}
	}, [isReadSpeakerLoaded])

	return (
		<ReadSpeakerPluginButton id="readspeaker_button1" className="rs_skip rsbtn rs_preserve">
			<ReadSpeakerPluginPlay
				rel="nofollow"
				className="rsbtn_play"
				accessKey="L"
				title={READ_SPEAKER_TEXT.title[language]}
				href={`https://app-eu.readspeaker.com/cgi-bin/rsent?customerid=9870&amp;lang=${langId}&amp;readid=${id}&amp;url=${encodeURI(
					absoluteUrl
				)}`}>
				<span className="rsbtn_left rsimg rspart">
					<span className="rsbtn_text">
						<span>{READ_SPEAKER_TEXT.label[language]}</span>
					</span>
				</span>
				<span className="rsbtn_right rsimg rsplay rspart"></span>
			</ReadSpeakerPluginPlay>
		</ReadSpeakerPluginButton>
	)
}
