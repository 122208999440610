import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Fotogaleria',
	[Language.En]: 'Photo gallery',
}

export const SvgPhotoGalleryMedium: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-photo-gallery-medium' + suffix

	return (
		<svg width={24} height={24} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g clipPath={`url(#${idPrefix}-clip0)`}>
				<g filter={`url(#${idPrefix}-filter0_d)`}>
					<path
						d="M18.735 15.429h3.035a.529.529 0 00.516-.541V3.968a.53.53 0 00-.516-.54H7.373a.529.529 0 00-.516.541v3.174h10.718c.64 0 1.16.545 1.16 1.217v7.069z"
						fill="#fff"
					/>
				</g>
				<g filter={`url(#${idPrefix}-filter1_d)`}>
					<path
						d="M1.714 8.92v10.956c0 .326.264.59.59.59h14.49a.589.589 0 00.59-.59V8.92a.588.588 0 00-.59-.59H2.305a.589.589 0 00-.59.59z"
						fill="#fff"
					/>
				</g>
			</g>
			<defs>
				<filter
					id={`${idPrefix}-filter0_d`}
					x={4.857}
					y={2.429}
					width={19.429}
					height={16}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={1} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
				<filter
					id={`${idPrefix}-filter1_d`}
					x={-0.286}
					y={7.33}
					width={19.669}
					height={16.135}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={1} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
				<clipPath id={`${idPrefix}-clip0`}>
					<path fill="#fff" d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	)
}
