import React from 'react'
import styled from 'styled-components'
import { Text, TextTypes } from 'components/text/text'
import { useLocationContext } from 'components/location-provider/location-provider'
import { useFormattedDate } from 'hooks/use-formatted-date'

type DateTextProps = {
	timestamp: string
}

export const DateTextWrapper = styled(Text)`
	margin-bottom: 0;
	color: ${props => props.theme.text.light};
`

const DateTextComponent = ({ timestamp }: DateTextProps) => {
	const { language } = useLocationContext()
	const dateFormatted = useFormattedDate(timestamp, language)

	return (
		<DateTextWrapper textType={TextTypes.paragraph}>
			<time dateTime={timestamp}>{dateFormatted}</time>
		</DateTextWrapper>
	)
}

export const DateText = React.memo(DateTextComponent)
