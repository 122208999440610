import { formatDistanceToNow, format, differenceInDays, isSameYear } from 'date-fns'
import { en, pt } from './locale'

type FormatOptions = {
	includeSeconds?: boolean
	addSuffix?: boolean
	locale?: Locale
}

export enum DateFormats {
	relative = 'relative',
	day = 'day',
	locale = 'locale',
}

const baseOptions = (language = 'pt') => {
	return { locale: language === 'en' ? en : pt }
}

export const formatDateRelative = (timestamp: string, options: FormatOptions = {}, language: string) => {
	if (isSameYear(new Date(timestamp), new Date())) {
		if (differenceInDays(new Date(), new Date(timestamp)) > 3) {
			return format(new Date(timestamp), 'PP', { ...baseOptions(language), ...options })
		}
		return formatDistanceToNow(new Date(timestamp), { ...baseOptions(language), ...options })
	} else {
		return format(new Date(timestamp), 'PPP', baseOptions(language))
	}
}

export const formatDateDay = (timestamp: string, language: string) => {
	return format(new Date(timestamp), language === 'en' ? 'MMM do' : 'dd MMM', baseOptions(language))
}

export const formatDateLocale = (timestamp: string, language: string, showYear = false) => {
	if (isSameYear(new Date(timestamp), new Date()) && !showYear) {
		return format(new Date(timestamp), 'PP', baseOptions(language))
	}
	return format(new Date(timestamp), 'PPP', baseOptions(language))
}

export const formatDateCustom = (date: Date, formatDate: string, language: string) => {
	return format(date, formatDate, baseOptions(language))
}

export const getWeekDays = (language: string) => {
	return [...Array(7).keys()].map(day => baseOptions(language).locale.localize?.day(day, { width: 'abbreviated' }))
}

export const formatDateTime = (timestamp: string, language: string) => {
	return format(new Date(timestamp), 'p', baseOptions(language))
}
