import React from 'react'
import styled, { css } from 'styled-components'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { transition } from 'theme/utils'
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { NextLink } from 'components/next-link/next-link'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

type RichTextBlockProps = {
	html: string
}

const baseStyle = css`
	font-family: ${props => props.theme.fonts.secondary};
	color: ${props => props.theme.text.primary};
`

const baseHeadingStyle = css`
	margin: 0 0 24px;
	font-family: ${props => props.theme.fonts.primary};
	font-weight: 700;
	color: ${props => props.theme.text.primary};
`

const mobileFontSize = css`
	font-size: 16px;
	line-height: 28px;
`

const desktopFontSize = css`
	font-size: 18px;
	line-height: 34px;
`

const RichTextBlockWrapper = styled.div`
	p {
		margin: 0 0 24px;
		overflow: hidden;
		clear: both;
		${baseStyle}
		${mobileFontSize}
		${mediaBreakpointUpLg} {
			${desktopFontSize}
		}

		/* Avoid accumulative margins */
		a,
		img,
		div {
			margin: 0;
		}
	}

	b {
		font-weight: 700;
	}

	i {
		font-style: italic;
	}

	h1 {
		${baseHeadingStyle}
		margin: 0 0 16px;
		font-size: 28px;
		line-height: 30px;
		${mediaBreakpointUpLg} {
			font-size: 40px;
			line-height: 44px;
		}
	}

	h2 {
		${baseHeadingStyle}
		font-size: 24px;
		line-height: 28px;
		${mediaBreakpointUpLg} {
			font-size: 34px;
			line-height: 38px;
		}
	}

	h3 {
		${baseHeadingStyle}
		font-size: 16px;
		line-height: 28px;
		${mediaBreakpointUpLg} {
			font-size: 30px;
			line-height: 32px;
		}
	}

	h4 {
		${baseHeadingStyle}
		font-size: 16px;
		line-height: 28px;
		${mediaBreakpointUpLg} {
			font-size: 28px;
			line-height: 28px;
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	a {
		font-weight: 700;
		color: ${props => props.theme.color.primary};
		text-decoration: underline;
		transition: ${transition('color')};

		&:hover,
		&:focus,
		&:active {
			color: ${props => props.theme.color.secondary};
		}
	}

	ul,
	ol {
		padding: 0 0 0 25px;
		margin: 0 0 24px;
		list-style: none;

		li {
			ul,
			ol {
				margin: 0;
			}
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	li {
		position: relative;
		margin: 0 0 4px;
		${baseStyle}
		${mobileFontSize}
		${mediaBreakpointUpLg} {
			font-size: 18px;
			line-height: 34px;
		}

		&:last-child {
			margin: 0;
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	ul {
		/* stylelint-disable-next-line no-descending-specificity */
		li {
			&::before {
				position: absolute;
				top: 13px;
				left: -16px;
				width: 8px;
				height: 4px;
				content: ' ';
				background: ${props => props.theme.color.primary};

				${mediaBreakpointUpLg} {
					top: 15px;
				}
			}
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	ol {
		counter-reset: item;

		/* stylelint-disable-next-line no-descending-specificity */
		li {
			counter-increment: item;

			&::before {
				position: absolute;
				top: 8px;
				left: -25px;
				font-family: ${props => props.theme.fonts.primary};
				font-size: 16px;
				font-weight: 700;
				line-height: 1em;
				color: ${props => props.theme.color.primary};
				content: counter(item) '. ';

				${mediaBreakpointUpLg} {
					top: 11px;
				}
			}
		}
	}

	hr {
		margin: 32px 0;
		border: 0;
		border-top: 1px solid ${props => props.theme.colors.lightPeriwinkle};
	}

	/* stylelint-disable-next-line no-descending-specificity */
	img {
		max-width: 100%;
		height: auto;
		margin: 0 0 32px;
	}

	.richtext-image {
		&.full-width {
			display: block;
			margin-right: auto;
			margin-left: auto;
			clear: both;
		}

		&.left {
			float: left;
			margin-right: 32px;
		}

		&.right {
			float: right;
			margin-left: 32px;
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	div {
		margin: 0 0 32px;
	}

	.responsive-object {
		position: relative;
		padding-bottom: 56.25%;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
		}
	}
`

const COOKIE_SETTINGS_TITLE = {
	[Language.Pt]: 'Definições de cookies',
	[Language.En]: 'Cookie settings',
}

export const RichTextBlock = ({ html }: RichTextBlockProps) => {
	const { language } = useLocationContext()
	const htmlDom = html
		.replace(/(<a\s?href="([^<>]+)">([^<>]|<[bi]>|<\/[bi]>)+<\/a>)/g, '<next-link href="$2">$1</next-link>')
		.replace(
			'{{ COOKIES_TABLE_HERE }}',
			`<a class="optanon-show-settings">${COOKIE_SETTINGS_TITLE[language]}</a><div id="optanon-cookie-policy"></div>`
		)
	const options: HTMLReactParserOptions = {
		replace: ({ type, name, attribs, children }) => {
			if (type === 'tag' && name === 'next-link' && children) {
				return <NextLink {...(attribs as any)}>{domToReact(children, options)}</NextLink>
			}
		},
	}
	return <RichTextBlockWrapper>{parse(htmlDom, options)}</RichTextBlockWrapper>
}
