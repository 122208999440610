import React from 'react'
import PropTypes from 'prop-types'
import { SvgArrowRight } from 'components/svgs/svg-arrow-right'
import styled from 'styled-components'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { NextLink } from 'components/next-link/next-link'
import { transition } from 'theme/utils'

type PrimaryButtonProps = {
	suffix: string
	href: string
	children: PropTypes.ReactNodeLike
	showArrow?: boolean
	small?: boolean
}

const PrimaryButtonLink = styled.a<{ small: boolean }>`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: ${props => props.theme.color.primary};
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: ${props => (props.small ? '0px' : '0.4px')};
	transition: ${transition('color')};

	${mediaBreakpointUpLg} {
		justify-content: flex-start;
	}

	span {
		font-size: ${props => (props.small ? '12px' : '16px')};
		font-weight: 700;
		line-height: 1;
	}

	svg {
		margin-bottom: 2px;
		margin-left: 8px;

		path {
			stroke: ${props => props.theme.color.primary};
			transition: ${transition('stroke')};
		}
	}

	&:hover {
		color: ${props => props.theme.color.secondary};
		text-decoration: none;

		svg path {
			stroke: ${props => props.theme.color.secondary};
		}
	}
`

export const PrimaryButton = ({ suffix, href, children, showArrow, small = false }: PrimaryButtonProps) => {
	return (
		<NextLink href={href} passHref>
			<PrimaryButtonLink small={small}>
				<span>{children}</span>
				{showArrow && <SvgArrowRight suffix={suffix} />}
			</PrimaryButtonLink>
		</NextLink>
	)
}

PrimaryButton.defaultProps = {
	showArrow: true,
	small: false,
}
