import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaBreakpointUpLg } from 'theme/breakpoints'
import { PrimaryButton } from 'components/primary-button/primary-button'

type SectionTitleProps = {
	suffix: string
	children: PropTypes.ReactNodeLike
	link?: {
		label: string
		href: string
	}
	isSidebar?: boolean
}

const SectionTitleWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	&::after {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: 4px;
		content: ' ';
		background-color: ${props => props.theme.color.primary};
	}
`

const SectionTitleText = styled.h2<{ isSidebar: boolean }>`
	padding-bottom: 14px;
	margin: 0;
	font-size: 28px;
	font-weight: 700;
	line-height: 34px;
	color: ${props => props.theme.text.primary};
	letter-spacing: -0.2px;

	${mediaBreakpointUpLg} {
		padding-bottom: ${props => (props.isSidebar ? '12px' : '24px')};
		font-size: ${props => (props.isSidebar ? '34px' : '40px')};
		line-height: ${props => (props.isSidebar ? '42px' : '48px')};
	}
`

const SectionTitleLink = styled.div<{ isSidebar: boolean }>`
	display: none;

	${mediaBreakpointUpLg} {
		display: flex;
		align-items: center;
		padding-bottom: ${props => (props.isSidebar ? '15px' : '24px')};
		margin-right: 2px;
		margin-left: auto; /* Fix IE11 'space-between' */
	}
`

const SectionTitleComponent = ({ suffix, children, link, isSidebar = false }: SectionTitleProps) => {
	return (
		<SectionTitleWrapper>
			<SectionTitleText isSidebar={isSidebar}>{children}</SectionTitleText>
			{link && (
				<SectionTitleLink isSidebar={isSidebar}>
					<PrimaryButton suffix={suffix} href={link.href}>
						{link.label}
					</PrimaryButton>
				</SectionTitleLink>
			)}
		</SectionTitleWrapper>
	)
}

export const SectionTitle = React.memo(SectionTitleComponent)
