import { buildFormatLongFn } from '../utils'

const dateFormats = {
	full: "EEEE, d 'de' MMMM',' y",
	long: "d 'de' MMMM 'de' y",
	medium: "d 'de' MMMM",
	short: 'dd/MM/y',
}

const timeFormats = {
	full: 'HH:mm:ss zzzz',
	long: 'HH:mm:ss z',
	medium: 'HH:mm:ss',
	short: 'HH:mm',
}

const dateTimeFormats = {
	full: "{{date}} 'às' {{time}}",
	long: "{{date}} 'às' {{time}}",
	medium: '{{date}}, {{time}}',
	short: '{{date}}, {{time}}',
}

export const formatLong = {
	date: buildFormatLongFn({
		formats: dateFormats,
		defaultWidth: 'full',
	}),

	time: buildFormatLongFn({
		formats: timeFormats,
		defaultWidth: 'full',
	}),

	dateTime: buildFormatLongFn({
		formats: dateTimeFormats,
		defaultWidth: 'full',
	}),
}
