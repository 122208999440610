import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Reproduzir',
	[Language.En]: 'Play',
}

export const SvgPlayExtraLarge: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-play-extra-large' + suffix

	return (
		<svg width={70} height={72} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g filter={`url(#${idPrefix}-filter0_d)`}>
				<path
					d="M35 4c17.12 0 31 13.879 31 31 0 17.12-13.878 31-31 31C17.88 66 4 52.12 4 35 4 17.879 17.878 4 35 4zm-5.034 21.312a.872.872 0 00-.87.868v17.64a.867.867 0 00.873.87.873.873 0 00.436-.12l15.12-8.82a.869.869 0 000-1.5l-15.12-8.818a.871.871 0 00-.44-.119z"
					fill="#fff"
				/>
			</g>
			<defs>
				<filter
					id={`${idPrefix}-filter0_d`}
					x={0}
					y={2}
					width={70}
					height={70}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={2} />
					<feGaussianBlur stdDeviation={2} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}
