import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Reproduzir',
	[Language.En]: 'Play',
}

export const SvgPlayMedium: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-play-medium' + suffix

	return (
		<svg width={28} height={28} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g filter={`url(#${idPrefix}-filter0_d)`} clipPath={`url(#${idPrefix}-clip0)`}>
				<path
					d="M14 2c6.628 0 12 5.372 12 12s-5.372 12-12 12S2 20.628 2 14 7.372 2 14 2zm-1.952 8.247a.333.333 0 00-.334.333v6.84a.333.333 0 00.501.288l5.863-3.42a.333.333 0 000-.576l-5.863-3.42a.333.333 0 00-.167-.045z"
					fill="#fff"
				/>
			</g>
			<defs>
				<clipPath id={`${idPrefix}-clip0`}>
					<path fill="#fff" d="M0 0h28v28H0z" />
				</clipPath>
				<filter
					id={`${idPrefix}-filter0_d`}
					x={0}
					y={1}
					width={28}
					height={28}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={1} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}
