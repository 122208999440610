import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Fotogaleria',
	[Language.En]: 'Photo gallery',
}

export const SvgPhotoGallery: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-photo-gallery' + suffix

	return (
		<svg width={28} height={28} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g filter={`url(#${idPrefix}-filter0_d)`}>
				<path
					d="M21.857 18h3.541c.333 0 .602-.283.602-.631V4.63c0-.347-.27-.63-.602-.63H8.602C8.269 4 8 4.283 8 4.631v3.702h12.504c.746 0 1.353.636 1.353 1.42V18z"
					fill="#fff"
				/>
			</g>
			<g filter={`url(#${idPrefix}-filter1_d)`}>
				<path
					d="M2 10.406v12.783c0 .38.308.687.687.687h16.907c.38 0 .687-.308.687-.687V10.406a.687.687 0 00-.687-.688H2.687a.687.687 0 00-.687.688z"
					fill="#fff"
				/>
			</g>
			<defs>
				<filter
					id={`${idPrefix}-filter0_d`}
					x={6}
					y={4}
					width={22}
					height={18}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={2} />
					<feGaussianBlur stdDeviation={1} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
				<filter
					id={`${idPrefix}-filter1_d`}
					x={0}
					y={9.718}
					width={22.281}
					height={18.158}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={2} />
					<feGaussianBlur stdDeviation={1} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}
