import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Reproduzir',
	[Language.En]: 'Play',
}

export const SvgPlayLarge: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-play-large' + suffix

	return (
		<svg width={54} height={56} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g filter={`url(#${idPrefix}-filter0_d)`}>
				<path
					d="M27 3c13.255 0 24 10.745 24 24 0 13.254-10.745 24-24 24C13.746 51 3 40.254 3 27 3 13.745 13.745 3 27 3zm-3.897 16.5a.674.674 0 00-.674.672v13.655a.671.671 0 00.676.674.675.675 0 00.338-.093l11.705-6.828a.672.672 0 000-1.161l-11.705-6.828a.676.676 0 00-.34-.091z"
					fill="#fff"
				/>
			</g>
			<defs>
				<filter
					id={`${idPrefix}-filter0_d`}
					x={0}
					y={2}
					width={54}
					height={54}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={2} />
					<feGaussianBlur stdDeviation={1.5} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}
