import { buildFormatLongFn } from '../utils'

const dateFormats = {
	full: 'EEEE, MMMM do, y',
	long: 'MMMM do, y',
	medium: 'MMMM do',
	short: 'MM/dd/yyyy',
}

const timeFormats = {
	full: 'h:mm:ss a zzzz',
	long: 'h:mm:ss a z',
	medium: 'h:mm:ss a',
	short: 'h:mm a',
}

const dateTimeFormats = {
	full: "{{date}} 'at' {{time}}",
	long: "{{date}} 'at' {{time}}",
	medium: '{{date}}, {{time}}',
	short: '{{date}}, {{time}}',
}

export const formatLong = {
	date: buildFormatLongFn({
		formats: dateFormats,
		defaultWidth: 'full',
	}),

	time: buildFormatLongFn({
		formats: timeFormats,
		defaultWidth: 'full',
	}),

	dateTime: buildFormatLongFn({
		formats: dateTimeFormats,
		defaultWidth: 'full',
	}),
}
