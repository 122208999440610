import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Title } from 'components/title/title'
import { mediaBreakpointUpLg } from 'theme/breakpoints'

type PageTitleProps = {
	prependTitle?: string
	children: PropTypes.ReactNodeLike
}

const PageTitleWrapper = styled(Title)`
	margin: 0;
	line-height: 32px;

	${mediaBreakpointUpLg} {
		line-height: 44px;
	}
`

export const PageTitlePrepend = styled.span`
	font-weight: 500;

	&::after {
		margin: 0 7px;
		font-weight: 700;
		content: '/';
	}
`

export const PageTitle = ({ prependTitle, children }: PageTitleProps) => {
	return (
		<PageTitleWrapper>
			{prependTitle && <PageTitlePrepend>{prependTitle}</PageTitlePrepend>}
			{children}
		</PageTitleWrapper>
	)
}
