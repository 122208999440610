import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Seta para a direita',
	[Language.En]: 'Arrow right',
}

export const SvgArrowRight: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-arrow-right' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={15} height={10} {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<path
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M10 1l4 4-4 4M1 5h12"
			/>
		</svg>
	)
}
