import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaBreakpointUpLg } from 'theme/breakpoints'

type BlockQuoteBlockProps = {
	cite?: string
	children: PropTypes.ReactNodeLike
}

const BlockQuoteBlockWrapper = styled.blockquote`
	position: relative;
	padding: 40px 0 0;
	margin: 32px 0;

	&::before {
		position: absolute;
		top: 0;
		left: -6px;
		font-family: ${props => props.theme.fonts.primary};
		font-size: 75px;
		font-style: italic;
		font-weight: 700;
		line-height: 1;
		color: ${props => props.theme.color.primary};
		content: '“';
	}

	p {
		margin: 0;
		font-family: ${props => props.theme.fonts.primary};
		font-size: 20px;
		font-style: italic;
		font-weight: 700;
		line-height: 24px;
		color: ${props => props.theme.text.primary};
		word-break: break-word;

		${mediaBreakpointUpLg} {
			font-size: 24px;
			line-height: 32px;
		}
	}
`

export const BlockQuoteBlock = ({ cite, children }: BlockQuoteBlockProps) => {
	return (
		<BlockQuoteBlockWrapper {...(cite && { cite })}>
			<p>{children}</p>
		</BlockQuoteBlockWrapper>
	)
}
