const formatDistanceLocale = {
	lessThanXSeconds: {
		one: 'Less than a minute',
		other: 'Less than {{count}} minutes',
	},

	xSeconds: {
		one: 'Less than a minute',
		other: 'Less than {{count}} minutes',
	},

	halfAMinute: 'Less than a minute',

	lessThanXMinutes: {
		one: 'Less than a minute',
		other: 'Less than {{count}} minutes',
	},

	xMinutes: {
		one: '1 minute',
		other: '{{count}} minutes',
	},

	aboutXHours: {
		one: '1 hour',
		other: '{{count}} hours',
	},

	xHours: {
		one: '1 hour',
		other: '{{count}} hours',
	},

	xDays: {
		one: '1 day',
		other: '{{count}} days',
	},

	aboutXMonths: {
		one: '1 month',
		other: '{{count}} months',
	},

	xMonths: {
		one: '1 month',
		other: '{{count}} months',
	},

	aboutXYears: {
		one: '1 year',
		other: '{{count}} years',
	},

	xYears: {
		one: '1 year',
		other: '{{count}} years',
	},

	overXYears: {
		one: 'over 1 year',
		other: 'over {{count}} years',
	},

	almostXYears: {
		one: 'almost 1 year',
		other: 'almost {{count}} years',
	},
}

export const formatDistance = (token: string, count: number, options: any) => {
	options = options || {}

	let result: string
	// @ts-ignore
	if (typeof formatDistanceLocale[token] === 'string') {
		// @ts-ignore
		result = formatDistanceLocale[token]
	} else if (count === 1) {
		// @ts-ignore
		result = formatDistanceLocale[token].one
	} else {
		// @ts-ignore
		result = formatDistanceLocale[token].other.replace('{{count}}', count)
	}

	if (options.addSuffix) {
		return result + ' ago'
	}

	return result
}
