import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Reproduzir',
	[Language.En]: 'Play',
}

export const SvgPlaySmall: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-play-small' + suffix

	return (
		<svg width={24} height={24} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<g filter={`url(#${idPrefix}-filter0_d)`} clipPath={`url(#${idPrefix}-clip0)`}>
				<path
					d="M12 2c5.522 0 10 4.478 10 10s-4.478 10-10 10S2 17.522 2 12 6.478 2 12 2zm-1.58 6.906a.324.324 0 00-.325.325v5.538a.325.325 0 00.487.28l4.748-2.77a.325.325 0 000-.559l-4.748-2.77a.324.324 0 00-.163-.044z"
					fill="#fff"
				/>
			</g>
			<defs>
				<clipPath id={`${idPrefix}-clip0`}>
					<path fill="#fff" d="M0 0h24v24H0z" />
				</clipPath>
				<filter
					id={`${idPrefix}-filter0_d`}
					x={0}
					y={1}
					width={24}
					height={24}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB">
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={1} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}
