import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaBreakpointUpSm, mediaBreakpointUpLg, mediaBreakpointUpXl } from 'theme/breakpoints'
import { resetButtonStyles, transition } from 'theme/utils'

export type TabsItem = {
	title: string
	content: PropTypes.ReactNodeLike
}

type TabsProps = {
	tabs: TabsItem[]
}

type TabButtonProps = {
	index: number
	className?: string
	title?: string
	isActive: boolean
	handleTabClick: (index: number) => void
	children: PropTypes.ReactNodeLike
}

const TabsWrapper = styled.div``

const TabsHeader = styled.header`
	position: relative;
	display: flex;

	&::before {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		content: ' ';
		background: ${props => props.theme.colors.lightPeriwinkle};
	}
`

const TabsBody = styled.div``

const TabButtonWrapper = styled.button<{ isActive?: boolean }>`
	position: relative;
	flex-basis: 0;
	flex-grow: 1;
	padding: 15px 0;
	font-family: ${props => props.theme.fonts.primary};
	font-size: 20px;
	font-weight: ${props => (props.isActive ? 700 : 500)};
	line-height: 1;
	color: ${props => props.theme.text.primary};
	text-align: center;
	transition: ${transition('color')};

	${resetButtonStyles}
	${mediaBreakpointUpSm} {
		font-size: 24px;
	}

	${mediaBreakpointUpLg} {
		font-size: 20px;
	}

	${mediaBreakpointUpXl} {
		padding: 15px 8px 16px 8px;
		font-size: 24px;
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 4px;
		content: ' ';
		background: ${props => (props.isActive ? props.theme.color.primary : 'transparent')};
		transition: ${transition('background')};
	}

	&:hover,
	&:focus,
	&:active {
		color: ${props => (props.isActive ? props.theme.text.primary : props.theme.text.secondary)};
	}

	&:focus {
		outline: none;
	}
`

export const TabButton = ({ index, handleTabClick, children, ...props }: TabButtonProps) => {
	return (
		<TabButtonWrapper {...props} onClick={() => handleTabClick(index)}>
			{children}
		</TabButtonWrapper>
	)
}

export const Tabs = ({ tabs }: TabsProps) => {
	const [activeTabIndex, setActiveTabIndex] = useState(0)
	const activeTabContent = tabs[activeTabIndex].content

	const handleTabClick = (index: number): void => {
		setActiveTabIndex(index)
	}

	return (
		<TabsWrapper>
			<TabsHeader>
				{tabs.map((tab, index) => (
					<TabButton key={index} index={index} isActive={index === activeTabIndex} handleTabClick={handleTabClick}>
						{tab.title}
					</TabButton>
				))}
			</TabsHeader>
			<TabsBody>{activeTabContent}</TabsBody>
		</TabsWrapper>
	)
}
